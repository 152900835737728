import React, {useState} from 'react';

import logo from './logo.png';
import './App.css';

const sections = [
  {name: "about us", id: "about-us", content: "Tinkly Barter is a toy & game company based in Berkeley, California, specializing in the weird & wacky.", link: {}},
   {name: "products", id: "products", content: "Meet Benny, perhaps *the* world's Most stinky, lumpy, snaggle-toothed dog.", link: {text: "Benny World is here!", href: "https://www.bennythedogworld.com"}},
    {name: "contact us", id: "contact", content: "We can be reached at info at tinklybarter.com. We will get back to you within 24 hours.", link: {}}
     
];

const App = ()  => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSectionContent, setSelectedSectionContent] = useState(null);
  const [selectedSectionLink, setSelectedSectionLink] = useState({});
 
  const toggleShowSection = (sectionName, sectionContent, sectionLink) => {
    setSelectedSection(sectionName);
      setSelectedSectionLink(sectionLink);
    setSelectedSectionContent(sectionContent);
  
  };  
  const getSections = () => {
    return sections.map((s) => {
      return (
        <div key={s.id}>
          <h2 className={s.id === selectedSection ? "highlight-section" : "reg-section"} onClick={() => {toggleShowSection(s.id, s.content, s.link)}}>
            {s.name}
            </h2>
           
            </div>
      );
    })
  } 
  return (
    <div className="App">
      <header className="App-header">
        <img height="200px" width="300px" src={logo} className="App-logo" alt="logo" />
        <a href="/">
        <h1>  
          Tinkly Barter
        </h1> 
        </a>
         <div className="main-section-col">
          <div className="main-section-row">
              {getSections()}
          </div>
           <div className="main-section-row-content">
            
          {selectedSectionContent && 
            <p> {selectedSectionContent}</p>       
        }
         {selectedSectionLink && 
          <a target="_blank" href={selectedSectionLink.href}>{selectedSectionLink.text}</a>
         }
        
         </div>
        </div>
      </header>
      <footer><p className='montserrat'>copyright © 2024 tinkly barter</p></footer>
    </div>
  );
}

export default App;
